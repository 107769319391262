@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

body * {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
}

.logoBox img {
  display: block;
  max-height: inherit;
  max-width: inherit;
  margin: 0 auto;
}

.answers_list .answers_list__item {
  cursor: initial;
}

.answers_list .answers_list__item.answers_list__item--good {
  background-color: #d3edd4;
}

.answers_list .answers_list__item.answers_list__item--bad {
  background-color: #f6a7a7;
}

.calendar_description p {
  font-weight: 400;
  font-size: 14px;
  display: inline-block;
}

.calendar_description div:nth-of-type(1) {
  padding-left: 0.45rem;
  position: relative;
  border-left: 4px solid;
  border-color: #f15a22;
  border-radius: 3px;
}

.calendar_description p:nth-of-type(1).ride::before {
  background-color: #0acf83;
}

.calendar_description p:nth-of-type(1).test::before {
  background-color: #f44336;
}

.calendar_description p:nth-of-type(2) {
  font-weight: 400;
}

/* Calendar - React FullCalendar */

.fc .fc-toolbar-title {
  font-size: 1rem !important;
}

.fc-today-button {
  display: none !important;
}

.fc .fc-button .fc-icon {
  font-size: 0.8rem !important;
}

.fc .fc-col-header-cell-cushion,
.fc .fc-daygrid-day-number {
  color: #000;
}

.fc .fc-scroller-liquid-absolute,
.fc .fc-view-harness-active > .fc-view {
  position: relative !important;
}

.fc .fc-view-harness {
  height: max-content !important;
}

.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
  min-height: 0 !important;
}

.fc .fc-button-primary:focus {
  box-shadow: none !important;
}

.fc-daygrid-dot-event .fc-event-title {
  color: #000 !important;
}

body .fc a {
  color: #000;
}

/* end of Calendar */
